import React, { useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  Container,
  FormLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faPhone,
  faShippingFast,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import { stringify } from "../Utils/CommonUtils";
import UserContext from "../Store/UserContext";

const Popup = function (props) {
  const onClose = props.onClose;
  const onYes = props.onYes;
  const data = props.data;
  const userType = props.userType;
  const yes = props.yes || "Yes";
  const fromOrdersPage = props.fromOrdersPage || false;

  const [show, setShow] = useState(props.show); //using props.show works but not using 'show' not sure why
  // console.log("setShow val: ", show, props.show, props.msg);

  const onClickYes = () => {
    onClose();
    onYes(data);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          setShow(false);
          onClose();
        }}
        className="bg-86black"
      >
        <Modal.Header closeButton className="bg-86black visible-text">
          <Modal.Title>
            Want to {props.msg || ""} {data[userType].id === UserContext.getuserId() ? "your" : "this"} {props.type || ""} ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-86black visible-text">
          <Container>
            
            <Row>
              <Col>
                <Form.Label>Food</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.foodItem.name}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Quantity</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.quantity}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Price</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.foodItem.price}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Food Type</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.foodItem.foodType || ""}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>Meal Type</Col>
              <Col>
                <FormLabel>{data.mealType || ""}</FormLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Menu</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.foodItem.menu || ""}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Food Available Date</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.foodAvailableDate || ""}</Form.Label>
              </Col>
            </Row>
            {!fromOrdersPage && 
            <Row>
              <Col>
                <Form.Label>
                  {userType === "vendor" ? "Seller" : "Buyer"}
                </Form.Label>
              </Col>
              <Col>
                <Form.Label>{`${data[userType].name} (${data[userType].flat})`}</Form.Label>
              </Col>
            </Row>
            }
          </Container>
        </Modal.Body>

        <Modal.Footer className="text-center bg-86black">
          <Button variant="primary" onClick={() => onClickYes()}>
            {yes}
          </Button>
          <Button variant="secondary" onClick={() => onClose()}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const OrderPopup = function (props) {
  const onClose = props.onClose;
  const onUpdate = props.onUpdate;
  const onReview = props.onReview;
  const data = props.data;
  const canUpdate = data.vendorId === UserContext.getuserId();
  const canReview = data.customerId === UserContext.getuserId() && data.status === "READY_FOR_DELIVERY";

  const [userRating, setUserRating] = useState(0);
  const [tempUserRating, setTempUserRating] = useState(0);
  const [reviewComments, setReviewComments] = useState("");

  const statusArr = ["NEW", "INPROGRESS", "READY_FOR_DELIVERY"];

  const title = canUpdate ? "Update Order Status" : "Find Order Status";

  const pointercursorClass = canUpdate ? "pointercursor" : "";

  const [show, setShow] = useState(props.show); //using props.show works but not using 'show' not sure why
  console.log(
    "setShow val: ",
    show,
    props.show,
    props.msg,
    stringify(data)
  );

  const update = (status) => {
    if (canUpdate) {
      const newdata = { ...data };
      const currentStatus = data.status;
      if (statusArr.indexOf(currentStatus) < statusArr.indexOf(status)) {
        newdata.status = status;
        onUpdate(newdata);
        onClose();
      } else {
        console.log("Can't change status from", data.status, status);
      }
    }
  };

  const review = () => {
    if(canReview){
      const reviewData = {
        productId: data.foodItem.id,
        vendorId: data.vendorId,
        customerId: data.customerId,
        rating: userRating,
        comments: reviewComments
      }
      onReview(reviewData);
      onClose();
    }
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          setShow(false);
          onClose();
        }}
        className="bg-86black modal"
      >
        <Modal.Header closeButton className="bg-86black visible-text">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-86black visible-text">
          <Container>
            <Row>
              <Col>
                <Form.Label>Id</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.id}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Food</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.foodItem.name}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Quantity</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.quantity}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Price</Form.Label>
              </Col>
              <Col>
                <Form.Label>{data.foodItem.price}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>Type</Col>
              <Col>
                <FormLabel>{data.mealType}</FormLabel>
              </Col>
            </Row>
            <Row>
              <Col>Status</Col>
              <Col>
                <FormLabel>{data.status}</FormLabel>
              </Col>
            </Row>
            <Row>
              <Col>{data.vendorId === UserContext.getuserId() ? "Customer" : "Vendor"}</Col>
              <Col>
                <FormLabel>{data.vendorId === UserContext.getuserId() ? data.customerFlatNo : data.vendorFlatNo}</FormLabel>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer className="bg-86black visible-text justify-content-between">
          <Row className="text-start mr-auto">
            <Col
              className={`mx-4 ${pointercursorClass} icon ${
                data.status === statusArr[0] ? "active-icon" : ""
              }`}
              onClick={() => update(statusArr[0])}
            >
              <FontAwesomeIcon icon={faCheck} className="fa-3x" />
            </Col>

            <Col
              className={`mx-4 ${pointercursorClass} icon ${
                data.status === statusArr[1] ? "active-icon" : ""
              }`}
              onClick={() => update(statusArr[1])}
            >
              <FontAwesomeIcon icon={faCog} className="fa-3x fa-spin" />
            </Col>
            <Col
              className={`mx-4 ${pointercursorClass} icon ${
                data.status === statusArr[2] ? "active-icon" : ""
              }`}
              onClick={() => update(statusArr[2])}
            >
              <FontAwesomeIcon icon={faShippingFast} className="fa-3x" />
            </Col>
          </Row>
          <Row>
            <Col>
            <div className="pos-relative">
          {
            canReview &&
            (
              [1,2,3,4,5].map((v) => {
                return (
                  <FontAwesomeIcon icon={faStar} size="xl" className={(userRating >= v || tempUserRating >= v) ? "color-yellow" : "" } 
                  onClick={()=>setUserRating(v)} onMouseOver={()=>setTempUserRating(v)} onMouseLeave={()=>setTempUserRating(0)}/>
                );})
              
            )
          }
          </div>
          </Col>
          </Row>
          <Row>
            {canReview &&
            <Form.Control placeholder="Write your review" as="textarea" className="color-white" 
              rows={2} onChange={(evt) => setReviewComments(evt.target.value)}/>
            }
            
          </Row>

          <Row>
            <a href={"tel:+91" + (data.customerId === UserContext.getuserId() ? data.vendorPhoneNo : data.customerPhoneNo)}>
              <label className="visible-text">
                <FontAwesomeIcon icon={faPhone} size="2xl" style={{color: "green"}} />
                {data.customerId === UserContext.getuserId() ? data.vendorPhoneNo : data.customerPhoneNo} 
              </label>
            </a>
            {/* {data.customerId === UserContext.getuserId() ? } */}
          </Row>
          
          { canReview && 
          <Button variant="primary" onClick={() => review()}>
            Submit Review
          </Button>
          }
          <Button variant="secondary" onClick={() => onClose()}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Popup;
