import { faArrowRightArrowLeft, faBan, faBell, faBowlRice, 
          faCircleChevronDown, faCircleChevronUp, faCoins, faLock, 
          faPhone, faPlusSquare, faStar, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const HomePage = () => {
  const [container, setContainer] = useState({
      tips: false, 
      disclaimer: false, 
      contact: false,
      faq: false,
    }
  );

  const show = (name) => {
    const new_container = {...container};

    Object.keys(container).forEach((key, idx) => {
      new_container[key] = key === name ? !container[key] : false;
    });
    setContainer(new_container);
  };
  
  return (
    <>
      <h2 className="text-center py-2" style={{ color: "white" }}>
        Welcome to <i className="">e</i><i className="">Cook</i>
      </h2>
      <div className="text-center">
        <img src="/vantillu.png" width={50} alt="" />
      </div>

      <div className="my-3 mx-2" style={{color: "lightcoral", fontSize: 18}}>
        <FontAwesomeIcon icon={faBowlRice} size="xl" style={{color: "lightcoral"}} /> &nbsp;
        Feeling tired or simply don't feel like cooking? Try home made food from your very own neighbors.
      </div>

      <div className="mx-2 my-3" style={{color: "lightblue", fontSize: 18}}>
        <FontAwesomeIcon icon={faCoins} size="xl" style={{color: "lightblue"}} /> &nbsp;&nbsp;
        Are you a passionate home cook? Turn your passion into profit by sharing food with neighbors.
      </div>

      <div className="text-center activetab white-border mx-4 my-3"
        style={{ fontWeight: "bolder" }}>
        Food Sharing App
      </div>
      
        
      <div className="visible-text" 
        onClick={()=> show("tips")}>
        <FontAwesomeIcon icon={container.tips ? faCircleChevronUp: faCircleChevronDown} size="xl"/>
        <span className="underline"> Tips to use App</span>
      </div>
      { container.tips &&
        <>
        <div className="visible-text mx-2 my-4">
          <div>
            <pre>
              <FontAwesomeIcon icon={faPlusSquare} size="2xl" className="plus-icon" />
              <i> </i> Create Orders from <span className="underline">Buy / Sell </span>tabs
            </pre>
          </div>

          <div>
            <pre>
              <FontAwesomeIcon icon={faArrowRightArrowLeft} size="2xl" className="plus-icon" style={{color: "white"}}/>
              <i> </i> Switch between Buy / Sell tabs
            </pre>
          </div>

          <div>
            <pre>
              <FontAwesomeIcon icon={faStar} size="2xl" className="plus-icon"/>
              <i> </i> Give Rating to consumed Food
            </pre>
          </div>

          <div>
            <pre>
              <FontAwesomeIcon icon={faBell} size="2xl" className="plus-icon" />
              <i> </i> Get Notified on your Order Status
            </pre>
          </div>

          {/* <div>
            <label className="tab product-font" style={{fontWeight: "bold"}}>Buy</label>
            <label className="tab product-font mx-2" style={{fontWeight: "bold"}}>Sell</label>
            To Create Orders
          </div> */}

          <div className="my-2">
            <label className="tab product-font" style={{fontWeight: "bold"}}>Orders</label>
            <label className="mx-2">To Verify Orders Status</label>
          </div>

        </div>
      </>
      }

      <div className="visible-text mt-4"
        onClick={()=> show("disclaimer")}>
        <FontAwesomeIcon icon={container.disclaimer ? faCircleChevronUp: faCircleChevronDown} size="xl"/>
        <span className="underline"> Disclaimer</span>
      </div>
        { container.disclaimer &&
        <div className="visible-text mx-2 my-3">
          <div>
            <pre>
              <FontAwesomeIcon icon={faBan} size="2xl" style={{color: "red"}} />
              <i> </i> We don't share your phone number.
            </pre>
          </div>

          <div>
            <pre>
              <FontAwesomeIcon icon={faThumbsUp} size="2xl" className="plus-icon" />
              <i> </i> User registration requires approval before accessing the app, to ensure secure community.
            </pre>
          </div>

          <div>
            <pre>
              <FontAwesomeIcon icon={faPhone} size="2xl" className="plus-icon" />
              <i> </i> Your phone number is visible only to fellow residents of your apartment, for privacy
            </pre>
          </div>

          <div>
            <pre>
              <FontAwesomeIcon icon={faLock} size="2xl" className="plus-icon" />
              <i> </i> Be Assured, eCook App is secured by https, ensuring security & privacy.
            </pre>
          </div>

          
        </div>
        }

      <div className="visible-text mt-4" 
        onClick={()=>show("contact")}>
        <FontAwesomeIcon icon={container.contact ? faCircleChevronUp: faCircleChevronDown} size="xl"/>
        <span className="underline"> Contact us</span>
      </div>
      {
        container.contact &&
        <pre className="mx-4 mt-2 visible-text">
          <span className="underline">Email: </span>support@ecook.cloud
        </pre>
      }

      <div className="visible-text mt-4" 
        onClick={()=>show("faq")}>
        <FontAwesomeIcon icon={container.faq ? faCircleChevronUp: faCircleChevronDown} size="xl"/>
        <span className="underline"> FAQ</span>
      </div>
      {
        container.faq &&
        <>
        <pre className="mx-4 mt-1 visible-text">
          <span className="product-font">Q. Is this Paid App or Free?</span><br />
          <span className="font-lightblue">A. This is a Free App.</span>
        </pre>
        <pre className="mx-4 mt-1 visible-text">
          <span className="product-font">Q. Can I make payment to Neighbor throuh App?</span><br />
          <span className="font-lightblue">A. No, Payment has to be done in Cash/GPay etc...</span>
        </pre>
        <pre className="mx-4 mt-1 visible-text">
          <span className="product-font">Q. Can we share Food with outside of my community?</span><br />
          <span className="font-lightblue">A. No, at the moment this is only within community.</span>
        </pre>
        </>
      }

           
    </>
  );
};

export default HomePage;
