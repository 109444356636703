function replacer(key, value){
  if(key === "image") return "<image>";
  return value;
}

export const stringify = (data) => {
  if (data === {}) {
    return data;
  }
  if (Array.isArray(data)) {
    return JSON.stringify(data.map((e) => stringify(e)));
  } else if (data !== undefined) {
    return JSON.stringify(data, replacer);
  } 
};

export const file2base64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => cb(reader.result);
  reader.onerror = (error) => console.log("File Read Error", error);
};
