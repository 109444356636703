const SubscriptionsDataStore = {
  subscriptions: [],
  add(obj) {
    this.subscriptions.push(obj);
    console.log("Added subscription-", obj);
  },
  unsubscribe() {
    for (const obj of this.subscriptions) {
      obj.subscription.unsubscribe(obj.id);
      obj.client.deactivate();
      console.log("Unsubscribed-", obj.id);
    }
    this.subscriptions = [];
  },
};

export default SubscriptionsDataStore;
