import React, { useState } from "react";

import { Form } from "react-bootstrap";

import RestUtils from "../Services/Rest";

import "./Login.css";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import ApartmentUtils from "../Utils/ApartmentUtils";
import ApartmentSearchRecord from "../mobile/ApartmentSearchRecod";

//LRN: https://bobbyhadz.com/blog/react-prevent-page-refresh-on-form-submit

function SignupPage(props) {
  const phoneRE = /^[5-9]\d{9}$/;
  const textRE = /.+/;

  const apartments = ApartmentUtils.getApartments();

  const [searchResults, setSearchResults] = useState([]);

  const [data, setData] = useState({});

  const [enabled, setEnabled] = useState(false);

  const [screen, setScreen] = useState(0);

  const [validation, setValidation] = useState({
    phoneNo: false,
    password: false,
    name: false,
    block: false,
    flatNo: false,
    apartmentId: false,
  });

  

  // useEffect(()=>UserContext.setValue(""));

  const [userAlreadyExists, setUserAlreadyExists] = useState("");
  const [userCreationMsg, setUserCreationMsg] = useState("");
  const [userCreationFailedMsg, setUserCreationFailedMsg] = useState("");

  const search = (searchKey) => {
    onFieldChange("apartmentName", searchKey, textRE);
    if (searchKey === "") {
      setSearchResults([]);
    } else {
      const newsearchresults =
        apartments.filter((apartment) =>
          apartment.name.toLowerCase().includes(searchKey.toLowerCase())
        ) || [];

      setSearchResults(newsearchresults);
    }
  };

  const onApartmentSelect = (ap) => {
    setSearchResults([]);
    setData((olddata) => {
      const newdata = { ...olddata };
      newdata.apartmentId = ap.id;
      newdata.apartmentName = ap.name;
      return newdata;
    });
    setValidation((olddata) => {
      const newdata = { ...olddata };
      newdata.apartmentId = true;
      return newdata;
    });
    console.log("apartment", JSON.stringify(ap));
  };

  function onFieldChange(field, value, validator) {
    setUserCreationMsg("");
    const newData = { ...data };
    newData[field] = value;

    setData(newData);

    setValidation((olddata) => {
      const newdata_ = { ...olddata };
      newdata_[field] = value !== undefined && validator.test(value);
      if (field === "password" || field === "password2"){
        const pval = newData.password === newData.password2;
        newdata_["password2"] = pval;
        newdata_["password"] = pval;
      }
      if (field === "phoneNo" && newdata_[field]) {
        RestUtils.userExists(value, (res) => {
          if (res === true || res === "true") {
            setUserAlreadyExists("User Already Exists. Try Another.");
            newdata_[field] = false;
          } else {
            setUserAlreadyExists("");
          }
        });
      } else {
        setUserAlreadyExists("");
      }
      isEnabled(screen, newdata_);
      return newdata_;
    });
  }

  function onNext() {
    console.log("onNext - Screen no is: ", screen);
    const newScreen = screen + 1;
    setScreen((c) => c + 1);
    if (newScreen > 2) {
      signup();
    }
    console.log("onNext - isEnabled called.");
    isEnabled(newScreen, validation);
  }

  function signup() {
    console.log("Signup: " + JSON.stringify(data));
    RestUtils.signup(
      data,
      (res) => {
        setData({ isActive: "true" });
        setUserCreationMsg("User created successfully. ");
      },
      (err) => {
        setData({ isActive: "true" });
        setUserCreationFailedMsg("Invalid details, please try again.");
      }
    );
  }

  function isEnabled(screen_, newdata) {
    console.log("Screen:", screen, JSON.stringify(newdata));
    if (screen_ === 0) {
      setEnabled(!! newdata.phoneNo);
    } else if (screen_ === 1) {
      setEnabled(newdata.apartmentId && newdata.flatNo);
    } else if (screen_ === 2) {
      setEnabled(newdata.name && newdata.password);
    }
  }

  return (
    <>
      <Form className="login-form mt-5">
        <h2 className="text-center mb-5" style={{ color: "#adb3b6" }}>
          Welcome
        </h2>
        {screen === 0 && (
          <Form.Group className="visible-text mt-5">
            <Form.Label>Phone Number</Form.Label>

            <Form.Control
              type="text"
              required
              autoFocus
              placeholder="10 digits"
              value={data.phoneNo || ""}
              className="col-6"
              onChange={(event) =>
                onFieldChange("phoneNo", event.target.value, phoneRE)
              }
            />
            {data.phoneNo && !validation.phoneNo && !userAlreadyExists && (
              <div className="error-text-sm">
                Please enter a valid mobile number
              </div>
            )}
            {userAlreadyExists && (
              <div className="error-text-sm">{userAlreadyExists}</div>
            )}

            <Form.Label className="mt-4 visible-text">
              Already a member?{"  "}
              <Link className="visible-text underline" to="/login">
                Login now
              </Link>
            </Form.Label>
          </Form.Group>
        )}

        {screen === 1 && (
          <Form.Group>
            <div className="error-text-bg">{userCreationFailedMsg}</div>
            <Form.Label className="visible-text">Apartment</Form.Label>
            <Form.Control
              type="text"
              required
              autoFocus
              placeholder="Start typing to see onboarded apartments"
              value={data.apartmentName || ""}
              className={validation.apartmentId ? "" : "border-red"}
              onChange={(event) => search(event.target.value)}
            />
            {searchResults.slice(0, 15).map((apartment, key) => {
              return (
                <ApartmentSearchRecord
                  onSelect={() => onApartmentSelect(apartment)}
                  apartment={apartment}
                  key={key}
                />
              );
            })}

            <Form.Label className="visible-text">Block</Form.Label>
            <Form.Control
              type="text"
              required
              className={validation.block ? "" : "border-red"}
              value={data.block || ""}
              onChange={(event) =>
                onFieldChange("block", event.target.value, textRE)
              }
            />
            <Form.Label className="visible-text">FlatNo</Form.Label>
            <Form.Control
              type="text"
              required
              className={validation.flatNo ? "" : "border-red"}
              value={data.flatNo || ""}
              onChange={(event) =>
                onFieldChange("flatNo", event.target.value, textRE)
              }
            />
          </Form.Group>
        )}

        {screen === 2 && (
          <Form.Group>
            <Form.Label className="visible-text">Name</Form.Label>
            <Form.Control
              type="text"
              required
              autoFocus
              pattern={textRE.source}
              value={data.name || ""}
              className={validation.name ? "" : "border-red"}
              autoComplete="off"
              onChange={(event) =>
                onFieldChange("name", event.target.value, textRE)
              }
            />
            <Form.Label className="visible-text">Password</Form.Label>
            <Form.Control
              type="password"
              required
              pattern={textRE.source}
              value={data.password || ""}
              className={validation.password ? "" : "border-red"}
              autoComplete="new-password"
              onChange={(event) =>
                onFieldChange("password", event.target.value, textRE)
              }
            />
            <Form.Label className="visible-text">Retype Password</Form.Label>
            <Form.Control
              type="password"
              required
              pattern={textRE.source}
              value={data.password2 || ""}
              className={validation.password ? "" : "border-red"}
              autoComplete="new-password"
              onChange={(event) =>
                onFieldChange("password2", event.target.value, textRE)
              }
            />
            {data.password && !validation.password && (
              <div className="error-text-sm">
                Password not matching.
              </div>
            )}
          </Form.Group>
        )}

        {userCreationMsg && (
          <div className="my-3 hightlight-text">
            <>
              {userCreationMsg}
              <Link className="hightlight-text" to="/login">
                Login now
              </Link>
            </>
          </div>
        )}
        {!userCreationMsg && (
          <div className="text-center mt-3 visible-text">
            <button
              className="btn border-0 next-icon"
              disabled={!enabled}
              onClick={(e) => {
                e.preventDefault();
                onNext();
              }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleRight} size="3x" />
            </button>
          </div>
        )}
      </Form>
    </>
  );
}

export default SignupPage;
