import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RestUtils from "../Services/Rest";
import OrdersDataStore from "../Store/OrdersDataStore";
import { OrderPopup } from "./Popup";
import NotificationService from "../Services/NotificationService";
import UserContext from "../Store/UserContext";
import { RequestRecord } from "./VendorPage";
import { OfferRecord } from "./CustomerPage";
import RequestsDataStore from "../Store/RequestsDataStore";
import OffersDataStore from "../Store/OffersDataStore";
import { faArrowRightArrowLeft, faPhone } from "@fortawesome/free-solid-svg-icons";
import { stringify } from "../Utils/CommonUtils";

const OrdersRecord = (props) => {
  const [show, setShow] = useState(false);

  const data = props.data || {};
  const onUpdate = props.onUpdate;
  const statusFontClass = data.status === "PENDING" ? "status-red-font" : "status-green-font";

  const key = "P-" + props.ukey;

  const showPopup = () => {
    if (data.status !== "MATCHED") {
      setShow(true);

      console.log("showPopup:", show);
    }
  };

  const closePopup = () => {
    setShow(false);
    console.log("closePopup: ", show);
  };

  const onReview = (reviewData) => {
    RestUtils.post("/rating", reviewData, (data)=>{
      console.log("Data after review: " + JSON.stringify(data));
    });
  }

  return (
    <div className="pointercursor">
      <OrderPopup show={show} onClose={() => closePopup()} 
        onReview={(reviewData_) => onReview(reviewData_)}
        data={data} type="order" onUpdate={(status) => onUpdate(status)} key={key}/>

      <div className="container">
        <div className="row">
          
          <div className="col">
            <div className="border-dull-white record-p record" onClick={() => showPopup()} >
              <div className="row record-row-p">
                <div className="col">
                  <div className="product-font">
                    {(data.foodItem && data.foodItem.name) || ""}
                  </div>
                  <div className="image-container">
                    {data.foodItem.image && (
                      <img src={`${data.foodItem.image}`} alt="" />
                    )}
                    {!data.foodItem.image && <img src="/blank.png" alt="" />}
                  </div>
                </div>
                <div className="col font-coral text-right">
                  {"\u20B9"+(data.foodItem && data.foodItem.price) || ""}
                  {` (${data.quantity || ""})`}
                </div>
              </div>

              <div className="row record-row-p">
                <div className="col flat-font">{UserContext.getValue() === "customer" ? data.vendorFlatNo : data.customerFlatNo}</div>
                <div className={`col ${statusFontClass}`}>{data.status || "invalid-status"}</div>
                <div className="col flat-font text-right">
                  {data.mealType || "invalid-meal-type"}
                </div>
              </div>
            </div>
          </div>

          <div className="col-1">
            <a href={"tel:+91" + (data.customerId === UserContext.getuserId() ? data.vendorPhoneNo : data.customerPhoneNo)}>
              <FontAwesomeIcon icon={faPhone} size="lg" style={{color: "green", paddingTop: "35px"}} className="mt-4"/>
            </a>
          </div>
        </div>
      
      </div>

    </div>
  );
};

const OrdersPage = (props) => {
  const [ordersdata, setOrdersdata] = useState([]);
  const [ordersbkp, setOrdersbkp] = useState([]);

  const [requestsdata, setRequestsdata] = useState([]);
  const [offersdata, setOffersdata] = useState([]);

  const subscribe = () => {
    OrdersDataStore.subscribe("OrdPage", (msg, maction, source) =>
      notifyMe(msg, maction, source)
    );
    RequestsDataStore.subscribe("ReqOrdPage", (msg, maction, source) =>
      notifyMeReq(msg, maction, source)
    );
    OffersDataStore.subscribe("OfrOrdPage", (msg, maction, source) =>
      notifyMeOfr(msg, maction, source)
    );
  };

  useEffect(subscribe, []);

  useEffect(getOrders, []);

  useEffect(getOffers, []);

  useEffect(getRequests, []);

  function getOffers() {
    console.log("Making rest call - getOffers ");

    RestUtils.get("/offers", (res) => {
      OffersDataStore.add(res, "RESTAPI");
    });
  }

  function getRequests() {
    console.log("Making rest call - getRequests ");

    RestUtils.get("/requests", (res) => {
      RequestsDataStore.add(res, "RESTAPI");
    });
  }

  const notifyMe = (msg, maction, source) => {
    console.log("OrdersPage notifyMe", maction, source, msg);
    console.log("Source: ", source, "customerId:", msg.customerId, " vendorId:", msg.vendorId,
            "userId: ", UserContext.getuserId());

    if (source === "MQ" && msg.customerId === UserContext.getuserId()) {
      NotificationService.notify(`Order ${msg.foodItem.name} updated`);
    }

    setOrdersdata((oldData) => {
      const newData = [...oldData];
      if (maction === "add") {
        const arr = !Array.isArray(msg) ? [msg] : msg;
        
        for (const ele of arr) {
          const idx = newData.findIndex((o) => o.id === ele.id);
          if (idx !== -1) {
            const oldOrd = newData.splice(idx, 1)[0]; // at pos idx remove 1 element
          }
          newData.push(ele);
          // console.log("newData: " + stringify(newData));
        }
      } else {
        return newData.filter((v) => {
          return v.id !== msg.id;
        });
      }
      return newData;
    });

    //FIXME: after adding 'action' add/remove in above func, this needs to be in sync.
    setOrdersbkp((oldData) => {
      const newData = [...oldData];
      if (Array.isArray(msg)) {
        Array.prototype.push.apply(newData, msg);
      } else {
        newData.push(msg);
      }
      // console.log("New dataBkp is: " + stringify(newData));
      return newData;
    });
  };

  const notifyMeOfr = (msg, maction, source) => {
    setOffersdata((oldData) => {
      const newData = [...oldData];
      if (maction === "add") {
        const arr = !Array.isArray(msg) ? [msg] : msg;
        for (const ele of arr) {
          const idx = newData.findIndex((o) => o.id === ele.id);
          if (idx !== -1) {
            const oldOfr = newData.splice(idx, 1)[0]; // at pos idx remove 1 element
            if (
              source === "MQ" &&
              oldOfr.vendor.id === UserContext.getuserId()
            ) {
              NotificationService.notify(`Offer ${oldOfr.foodItem.name} updated.`);
            }
          }
          newData.push(ele);
          // console.log("newData: " + stringify(newData));
        }
      } else {
        return newData.filter((v) => {
          return v.id !== msg.id;
        });
      }
      return newData;
    });
  };

  const notifyMeReq = (msg, maction, source) => {
    // console.log("New req from ordpage: "+ stringify(msg));
    setRequestsdata((oldData) => {
      const newData = [...oldData];
      if (maction === "add") {
        const arr = !Array.isArray(msg) ? [msg] : msg;
        for (const ele of arr) {
          const idx = newData.findIndex(
            (o) => o !== undefined && o.id === ele.id
          );
          if (idx !== -1) {
            const oldReq = newData.splice(idx, 1)[0]; // at pos idx remove 1 element
            if (source === "MQ" && oldReq.customer.id === UserContext.getuserId()) {
              NotificationService.notify(`Request ${oldReq.foodItem.name} updated.`);
            }
          }
          newData.push(ele);
          // console.log("newData: " + stringify(newData));
        }
      } else {
        return newData.filter((v) => {
          return v.id !== msg.id;
        });
      }
      return newData;
    });
  };

  function search(val) {
    if (val) {
      const tmpData = ordersbkp.filter((row) => {
        return stringify(row).includes(val);
      });
      setOrdersdata(tmpData);
    } else {
      setOrdersdata(ordersbkp);
    }
  }

  function getOrders() {
    console.log("Making rest call - getOrders ");

    RestUtils.get("/orders?userId=" + UserContext.getuserId(), (res) => {
      OrdersDataStore.add(res, "RESTAPI");
      console.log("getOrders: ", res.length, JSON.stringify(res));
    });
  }

  function updateOrder(data) {
    RestUtils.put("/orders/" + data.id, data, (res) => {
      OrdersDataStore.add(res, "RESTAPI");
    });
  }

  const onUpdate = (data) => {
    console.log("Executing onUpdate", stringify(data));
    updateOrder(data);
  };

  function cancelOffer(data) {
    RestUtils.delete("/offers/cancel/" + data.id, (res) => {
      OffersDataStore.delete(res, "RESTAPI");
    });
  }

  function cancelRequest(data) {
    RestUtils.delete("/requests/cancel/" + data.id, (res) => {
      OffersDataStore.delete(res, "RESTAPI");
    });
  }

  return (
    <>
      <div className="inner-addon dual-color my-1">
        <div className="row marg">
          <div className="col marg inner-addon-left">
            <i className="fa left-fa">
              <FontAwesomeIcon icon="search" size="sm" />
            </i>
            <input
              type="text"
              className="form-control"
              placeholder="Search existing Orders"
              onChange={(event) => search(event.target.value)}
            />
          </div>
        </div>
      </div>
      {/* {console.log("UserContext is"+UserContext.getValue()+","+stringify(requestsdata) + "," +stringify(offersdata))} */}
      

      {/*FIXME: repeated code */}
      {UserContext.getValue() === "customer" && requestsdata
          .filter(
            (d) =>
              d.status !== "CANCELLED" &&
              d.status !== "MATCHED"
          )
          .filter((obj) => obj["customer"].id === UserContext.getuserId()).length > 0 && 
        <div className="font-lightblue text-center">
          <label>Food you want to Buy but Pending</label>
        </div>
      }

      {UserContext.getValue() === "customer" &&
        requestsdata
          .filter(
            (d) =>
              d.status !== "CANCELLED" &&
              d.status !== "MATCHED"
          )
          .filter((obj) => obj["customer"].id === UserContext.getuserId())
          .map((d, i) => (
            <RequestRecord data={d} key={i} onYes={(data) => cancelRequest(data)} fromOrdersPage={true}/>
          ))}

      {UserContext.getValue() === "vendor" && offersdata
        .filter(
          (d) =>
            d.status !== "CANCELLED" &&
            d.status !== "MATCHED"
        )
        .filter((obj) => obj["vendor"].id === UserContext.getuserId()).length > 0 && 
        <div className="font-lightblue text-center">
          <label className="py-1">Food you want to Sell but Pending</label>
        </div>
      }

      {UserContext.getValue() === "vendor" && 
        offersdata
        .filter(
          (d) =>
            d.status !== "CANCELLED" &&
            d.status !== "MATCHED"
        )
        .filter((obj) => obj["vendor"].id === UserContext.getuserId())
        .map((d, i) => (
          <OfferRecord data={d} key={i} onYes={(data) => cancelOffer(data)} fromOrdersPage={true}/>
        ))}

      {ordersdata && 
        ordersdata
        .filter((d) => d.status !== "CANCELLED")
        .filter((obj) => {
          return (
            (obj.customerId === UserContext.getuserId() && UserContext.getValue() === "customer")||
            (obj.vendorId === UserContext.getuserId() && UserContext.getValue() === "vendor")
          );
        }).length > 0
       && 
        <div className="font-lightblue text-center">
          <label className="pt-2">Your confirmed {UserContext.getValue() === "customer" ? "Buy" : "Sell"} Orders</label>
        </div>
      }
      {ordersdata
        .filter((d) => d.status !== "CANCELLED")
        .filter((obj) => {
          return (
            (obj.customerId === UserContext.getuserId() && UserContext.getValue() === "customer")||
            (obj.vendorId === UserContext.getuserId() && UserContext.getValue() === "vendor")
          );
        })
        .map((d, i) => (
          <OrdersRecord data={d} key={i} ukey={i} onUpdate={(rec) => onUpdate(rec)} />
        ))}
      <div>
      <div className="text-center product-font bottom-right">
        <div
          className="col link"
          onClick={() => UserContext.setValue(UserContext.switchTo())}
        >
          <Link to={"/"+UserContext.switchTo()}>
            <div className="circle icon-fa">
              <i>
                <FontAwesomeIcon icon={faArrowRightArrowLeft} size="lg" />
              </i>
              <i> </i>{UserContext.getValue() === "vendor" ? "Buy" : "Sell"}
            </div>
          </Link>
        </div>
      </div>
      <div className="text-center bottom-center">
        <label>You are in {UserContext.getValue() === "vendor" ? "Seller" : "Buyer"} context.</label>
      </div>
      </div>
    </>
  );
};

export default OrdersPage;
