import { useEffect } from "react";
import { Link } from "react-router-dom";
import AuthenticationService from "../Services/AuthenticationService";

const LogoutPage = () => {
  useEffect(() => {
    AuthenticationService.removeAuth();
  }, []);
  return (
    <>
      <div className="text-center pt-5" style={{ color: "white" }}>
        Thank you! Now you are logged out.{" "}
        <Link style={{ color: "white" }} to="/login">
          Login Again
        </Link>
      </div>
    </>
  );
};

export default LogoutPage;
