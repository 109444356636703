import { Button } from "react-bootstrap";

const PendingUsersRecord = (props) => {
  const user = props.user || {};
  const onSelect_ = props.onSelect;

  const onSelect = () => {
    onSelect_(user);
    console.log("PendingUsersRecord onselect called.", user);
  };

  return (
    <>
      <div className="row record-row-p border-dull-white">
        <div className="col-7 product-font">
          {`${user.name}, ${user.phoneNo}, ${user.block}-${user.flatNo}`}
        </div>
        <div className="col text-right">
          <Button
            className="btn-sm btn-block btn-success"
            onClick={() => onSelect()}
          >
            Activate
          </Button>
        </div>
      </div>
    </>
  );
};

export default PendingUsersRecord;
