import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";


const Rating = (props) => {

    return(
        <div className="">
            <span className="bg-green pos-relative">
                <div className="rating">
                    <label className="color-white">{props.rating}</label>
                    <FontAwesomeIcon icon={faStar} size="sm" className="color-white" /> 
                </div>
            </span>  
            <span className="reviews">
                ({props.rateCount} ratings)
            </span>
        </div>
    );
}

export default Rating;