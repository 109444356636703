import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RestUtils from "../Services/Rest";
import UserDataStore from "../Store/UserDataStore";
import PendingUsersRecord from "./PendingUsersRecord";

const Profile = () => {
  const isAdmin = UserDataStore.isAdmin();
  const [pendingUsers, setPendingUsers] = useState([]);
  const [profile, setProfile] = useState({});

  useEffect(
    () => RestUtils.get("/users/pending", (data) => setPendingUsers(data)),
    []
  );

  useEffect(() => {
    RestUtils.get("/users/me", (data) => setProfile(data));
  }, []);

  function activate(user) {
    RestUtils.post(`/users/${user.id}/activate/`, {}, (data) =>
      console.log(data)
    );
    RestUtils.get("/users/me", (data) => setProfile(data));
  }

  return (
    <>
      <div className="row">
        <div className="profile my-4 col">User Profile</div>
        <div className="col">
          <Link to="/logout">
            <div className="text-right fontAwesomeIcon">
              <FontAwesomeIcon icon={faPowerOff} />
            </div>
            <div className="text-right fontAwesomeIcon">Logout</div>
          </Link>
        </div>
      </div>

      <div className="mb-3">
        <div className="row product-font my-1">
          <div className="col">Name</div>
          <div className="col">{profile.name}</div>
        </div>
        <div className="row product-font my-1">
          <div className="col">PhoneNumber</div>
          <div className="col">{profile.phoneNo}</div>
        </div>
        <div className="row product-font my-1">
          <div className="col">Apartment</div>
          <div className="col">
            {profile.apartment && profile.apartment.name}
          </div>
        </div>
        <div className="row product-font my-1">
          <div className="col">Flat</div>
          <div className="col">
            {profile.block}-{profile.flatNo}
          </div>
        </div>
        <div className="row product-font my-1">
          <div className="col">Id</div>
          <div className="col">{profile.id}</div>
        </div>
      </div>

      {isAdmin && (
        <div>
          <div className="border-top profile my-5 font-weight-bold">
            Pending Users
          </div>
          <div>
            {pendingUsers.map((user, key) => {
              return (
                <PendingUsersRecord
                  onSelect={() => activate(user)}
                  user={user}
                  key={key}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
