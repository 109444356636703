import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Searchable.css";
import SearchRecord from "./SearchRecord";
import MenuUtils from "../Utils/MenuUtils";
import RestUtils from "../Services/Rest";
import RequestsDataStore from "../Store/RequestsDataStore";
import { Form } from "react-bootstrap";
import { faCalendarDay, faInr } from "@fortawesome/free-solid-svg-icons";
import MsgDataStore from "../Store/MsgDataStore";
import UserContext from "../Store/UserContext";

const NewRequest = () => {
  const numRE = /^[1-9]\d*$/;

  const foodItemsMenu = MenuUtils.getFoodItems();

  const defaultData = {
    customer: { id: UserContext.getuserId() },
    status: "PENDING",
    foodAvailableDate: "",
    dealExpiresIn: ""
  };

  const [data, setData] = useState(defaultData);
  const [foodItem, setFoodItem] = useState("");
  const [mealTypeActiveTab, setMealTypeActiveTab] = useState({});

  const [searchKey, setSearchKey] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isNewProductSelected, setIsNewProductSelected] = useState(false);

  const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const	today = new Date();
  const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  
  const todayDay = today.getDay();
  const todayDate = today.getDate();

  const tomorrowDay = tomorrow.getDay();
  const tomorrowDate = tomorrow.getDate();

  const [todayLable, setTodayLable] = useState("Today");
  const [tomorrowLable, setTomorrowLable] = useState("Tomorrow");

  function toggleTodayHover(){
    if(todayLable === "Today"){
      setTodayLable(week[todayDay] + " " + todayDate);
    }else{
      setTodayLable("Today");
    }
  }

  function toggleTomorrowHover(){
    if(tomorrowLable === "Tomorrow"){
      setTomorrowLable(week[tomorrowDay] + " " + tomorrowDate);
    }else{
      setTomorrowLable("Tomorrow");
    }
  }


  const navigate = useNavigate();

  const search = (searchKey_) => {
    setSearchKey(searchKey_);

    if (searchKey_ === "") {
      setSearchResults([]);
    } else {
      const newFilteredData = foodItemsMenu.filter((foodItem) =>
        foodItem.name.toLowerCase().includes(searchKey_.toLowerCase())
      );
      setSearchResults(newFilteredData);
    }
  };

  const onFoodItemSelect = (foodItem) => {
    if (foodItem.isnew) {
      setIsNewProductSelected(true);
    } else {
      setIsNewProductSelected(false);
    }
    setSearchKey("");
    setSearchResults([]);
    setFoodItem(foodItem);
  };

  function onDataFieldChange(field, value, validator) {
    if (validator === undefined || value === "" || validator.test(value)) {
      setData((olddata) => {
        const newdata = { ...olddata };
        newdata[field] = value;
        console.log("onDataFieldChange:", field, JSON.stringify(newdata));
        return newdata;
      });
    } else {
      console.log("Validation failed", validator, field, value);
    }
  }

  function onFoodItemFieldChange(field, value, validator) {
    if (validator === undefined || value === "" || validator.test(value)) {
      setFoodItem((olddata) => {
        const newdata = { ...olddata };
        newdata[field] = value;

        // console.log("field:", field, JSON.stringify(newdata));
        return newdata;
      });
    } else {
      console.log("Validation failed", validator, field, value);
    }
  }

  function onMealTypeChange(activeTab, mealType) {
    const mealTypeClasses = { ft1: "", ft2: "", ft3: "", ft4: "" };
    mealTypeClasses[activeTab] = "mtypeboxselect";
    setMealTypeActiveTab(mealTypeClasses);
    setData((olddata) => {
      const newdata = { ...olddata };
      newdata.mealType = mealType;
      return newdata;
    });
  }

  function save() {
    const newdata = { ...data };
    newdata["foodItem"] = foodItem;

    RestUtils.post("/requests", newdata, (res) => {
      RequestsDataStore.add(res, "RESTAPI");
      MsgDataStore.add("Order Successful. Check Orders tab.", "REQUEST");
    }, (err) => MsgDataStore.add("Order Failed, please retry.", "REQUEST"));

    setData(defaultData);
    setFoodItem({});

    navigate("/customer");
  }

  return (
    <>
      <div className="qty-price my-4">
        <label className="mb-3">
          <b>Create Buy Order</b>
        </label>
        <div className="inner-addon dual-color inner-addon-left">
          <i className="fa left-fa">
            <FontAwesomeIcon icon="search" size="sm" />
          </i>
          <input
            type="text"
            className="form-control"
            placeholder="Search for food product"
            value={searchKey}
            onChange={(event) => search(event.target.value)}
          />
        </div>
        {searchKey.length !== 0 && (
          <div className="dataResult">
            {
              <SearchRecord
                onSelect={() =>
                  onFoodItemSelect({ name: searchKey, isnew: true })
                }
                foodItem={{ name: searchKey, isnew: true }}
                key="key-0"
              />
            }
            {searchResults.slice(0, 15).map((foodItem, key) => {
              return (
                <SearchRecord
                  onSelect={() => onFoodItemSelect(foodItem)}
                  foodItem={foodItem}
                  key={key}
                />
              );
            })}
          </div>
        )}

        <div className="row my-3">
          <div className="col">FoodItem<i className={foodItem.name ? "" : "mandatory-field"}>*</i></div>
          <div className="col food-item-font">
            <input
              type="text"
              className="form-control"
              readOnly={!isNewProductSelected}
              value={foodItem.name || ""}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">Quantity<i className={data.quantity ? "" : "mandatory-field"}>*</i></div>
          <div className="col">Price<i className={foodItem.price ? "" : "mandatory-field"}>*</i></div>
        </div>
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              value={data.quantity || ""}
              onChange={(event) =>
                onDataFieldChange("quantity", event.target.value, numRE)
              }
            />
          </div>
          <div className="col">
            <div className="inner-addon dual-color">
              <i className="fa left-fa">
                <FontAwesomeIcon icon={faInr} size="sm" />
              </i>
              <input
                type="text"
                className="form-control"
                readOnly={!isNewProductSelected}
                value={foodItem.price || ""}
                onChange={(event) =>
                  onFoodItemFieldChange("price", event.target.value, numRE)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col visible-text">Menu</div>
        <div className="col food-item-font">
          <input
            type="text"
            className="form-control"
            value={foodItem.menu || ""}
            readOnly={!isNewProductSelected}
            onChange={(event) =>
              onFoodItemFieldChange("menu", event.target.value)
            }
          />
        </div>
      </div>
      
      {isNewProductSelected && (
        <>
          <div className="row my-3">
            <div className="col visible-text">Food Type<i className={foodItem.foodType ? "" : "mandatory-field"}>*</i></div>
            <div
              className="col food-item-font"
              value={foodItem.foodType || ""}
              onChange={(event) =>
                onFoodItemFieldChange("foodType", event.target.value)
              }
            >
              {MenuUtils.getFoodTypes().map((fType, idx) => (
                <Form.Check
                  inline
                  label={fType}
                  id={fType}
                  value={fType}
                  type="radio"
                  name="foodTypesGroup"
                  key={idx}
                />
              ))}
            </div>
          </div>
        </>
      )}

      {!isNewProductSelected && (
          <div className="row my-3 visible-text">
            <label className="col">Food Type</label>
            <div className="col food-item-font">
              <input type="text" className="form-control" value={foodItem.foodType || ""} readOnly />
            </div>
          </div>
      )}

      <div className="row" style={{ color: "#adb3b6" }}>
        <label>Meal Type<i className={data.mealType ? "" : "mandatory-field"}>*</i></label>
      </div>
      <div className="row">
        <div
          className={`col prod-type mtypebox ${mealTypeActiveTab.ft1}`}
          onClick={() => onMealTypeChange("ft1", "BREAK_FAST")}
        >
          <label className="ftypebox">Breakfast</label>
        </div>
        <div
          className={`col prod-type mtypebox ${mealTypeActiveTab.ft2}`}
          onClick={() => onMealTypeChange("ft2", "LUNCH")}
        >
          <label className="ftypebox">Lunch</label>
        </div>
      </div>
      <div className="row">
        <div
          className={`col prod-type mtypebox ${mealTypeActiveTab.ft3}`}
          onClick={() => onMealTypeChange("ft3", "SNACKS")}
        >
          <label className="ftypebox">Snacks</label>
        </div>
        <div
          className={`col prod-type mtypebox ${mealTypeActiveTab.ft4}`}
          onClick={() => onMealTypeChange("ft4", "DINNER")}
        >
          <label className="ftypebox">Dinner</label>
        </div>
      </div>

      <div>
      <label className="visible-text" style={{float: "left", marginRight: "20px", marginTop: "8px"}}>
         Select Date<i className={data.foodAvailableDate ? "" : "mandatory-field"}>*</i>: </label>
        <button
            className={data.foodAvailableDate === today.toISOString().split('T')[0] ? "btn btn-info" : "btn visible-text"}
            onClick={()=>onDataFieldChange("foodAvailableDate", today.toISOString().split('T')[0])}
            onMouseEnter={()=>toggleTodayHover()} onMouseLeave={()=>toggleTodayHover()}>
          <FontAwesomeIcon icon={faCalendarDay} size="lg" 
            className={data.foodAvailableDate === today.toISOString().split('T')[0] ? "" : "visible-text"} /><i> </i>
          <label className={data.foodAvailableDate === today.toISOString().split('T')[0] ? "" : "visible-text"}>{todayLable}</label>
        </button>

        <button style={{marginLeft: "20px"}}
            className={data.foodAvailableDate === tomorrow.toISOString().split('T')[0] ? "btn btn-info" : "btn visible-text"}
            onClick={()=>onDataFieldChange("foodAvailableDate", tomorrow.toISOString().split('T')[0])}
            onMouseEnter={()=>toggleTomorrowHover()} onMouseLeave={()=>toggleTomorrowHover()}>
          <FontAwesomeIcon className={data.foodAvailableDate === tomorrow.toISOString().split('T')[0] ? "" : "visible-text"}
            icon={faCalendarDay} size="lg" /><i> </i>
          <label className={data.foodAvailableDate === tomorrow.toISOString().split('T')[0] ? "" : "visible-text"}> {tomorrowLable}</label>

        </button>

      </div>

      <div className="row my-3 g-1" style={{ color: "#adb3b6" }}>
        <div className="col col-6">
          <label style={{ paddingTop: "4px" }}>This order expires in<i className={data.dealExpiresIn ? "" : "mandatory-field"}>*</i>:</label>
        </div>
        
        <div className="col col-2 col-md-1 ml-0">
          <input type="text" className="form-control" value={data.dealExpiresIn}
              onChange={(event) =>
                onDataFieldChange("dealExpiresIn", event.target.value)
              }
          />
        </div>
        <div className="col col-1">
          <label style={{ paddingTop: "5px" }}> Hrs</label>
        </div>
        
      </div>


      <div className="my-4 text-center">
        <div className="row">
          <div className="col-2 mx-5 px-5">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => save()}
              disabled={
                !data.quantity ||
                !foodItem ||
                !data.mealType ||
                !foodItem.price ||
                !foodItem.foodType ||
                !data.foodAvailableDate ||
                !data.dealExpiresIn
              }
            >
              Submit
            </button>
            </div>
            <div className="col-2">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => navigate("/customer")}
                >
                  Close
                </button>
              </div>
          </div>
          <div className="visible-text mt-2">* mandatory</div>
      </div>
      

    </>
  );
};

export default NewRequest;
