export default class NotificationService {
  static registration;
  static initialized = false;

  static init() {
    if (!this.initialized) {
      try {
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`);

        Notification.requestPermission(function (result) {
          if (result === "granted") {
            navigator.serviceWorker.ready.then(function (registration) {
              NotificationService.register(registration);
            });
          }
        });
      } catch (err) {
        console.log("Error in ServiceWorker registration.");
        console.log(err);
      }
      NotificationService.initialized = true;
    } else {
      console.log("Already initialized.");
    }
  }

  static register(reg) {
    NotificationService.registration = reg;
    console.log("ServiceWorker is registered.");
    // NotificationService.registration.showNotification(
    //   "Live updates are active."
    // );
  }

  static notify(text) {
    console.log("Notify msg: ", text);
    if (NotificationService.registration) {
      NotificationService.registration.showNotification(text);
    } else {
      console.log("ServiceWorker is not register.");
    }
  }
}
