// import the library
import { library, solid, regular } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { faCode, faHighlighter } from "@fortawesome/free-solid-svg-icons";
import {
  faTrash,
  faHome,
  faEdit,
  faQrcode,
  faLink,
  faEnvelope,
  faSearch,
  faFileAlt,
  faPepperHot,
  faSignOutAlt,
  faCoffee,
  faUser,
  faWheatAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCode,
  faHighlighter,
  faTrash,
  faHome,
  faEdit,
  faQrcode,
  faLink,
  faEnvelope,
  faSearch,
  faFileAlt,
  faPepperHot,
  faSignOutAlt,
  faCoffee,
  faUser,
  faWheatAlt,
  faStar
);
