import React, { useState } from "react";

import { Link, Navigate } from "react-router-dom";

import { Button, Form } from "react-bootstrap";

import AuthenticationService from "../Services/AuthenticationService";

import "./Login.css";

function SigninPage(props) {
  
  const phoneRE = /^[0-9]*$/;

  const [data, setData] = useState({ username: "", password: "" });

  const [loginStatus, setLoginStatus] = useState(false);

  function onFieldChange(field, value) {
    const newData = { ...data };
    newData[field] = value;
    if(field === "username"){
      if(phoneRE.test(value)){
        setData(newData);
      }
    }else{
      setData(newData);
    }
    // console.log("New data is: " + field + ", " + JSON.stringify(newData));
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.

  //FIXME: if we move this func outside of funcComp, it gets called everytime 'data' changes.
  function login() {
    AuthenticationService.authenticate(
      data,
      (status) => setLoginStatus(status),
      (err) => setLoginStatus(err.data)
    );
    // setData({ username: "", password: "" });
  }

  if (loginStatus === true) {
    return <Navigate to="/home" />;
  } else {
    return (
      <>
        <Form className="login-form mt-4">
          <h2 className="text-center" style={{ color: "#adb3b6" }}>
            Welcome
          </h2>
          <div className="error-text-bg mt-4">
            {loginStatus !== true ? loginStatus : ""}
          </div>
          <Form.Group>
            <Form.Label className="mt-3 visible-text">PhoneNo</Form.Label>
            <Form.Control
              type="text"
              required
              autoFocus
              value={data.username || ""}
              onChange={(event) =>
                onFieldChange("username", event.target.value)
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="mt-4 visible-text">Password</Form.Label>
            <Form.Control
              type="password"
              required
              value={data.password || ""}
              onChange={(event) =>
                onFieldChange("password", event.target.value)
              }
            />
          </Form.Group>

          <Form.Group>
            <div className="text-center mt-3">
              <Button
                className="btn-lg btn-block btn-success"
                onClick={login}
                disabled={!data.username || !data.password}
              >
                Login
              </Button>
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label className="mt-4 visible-text">
              Not a member?{"  "}
              <Link className="underline" to="/signup">
                Sign up now
              </Link>
            </Form.Label>
          </Form.Group>
        </Form>
      </>
    );
  }
}

export default SigninPage;
