import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import NotificationService from "./Services/NotificationService";
import StompClient from "./mobile/StompClient";

const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.REACT_APP_ENVMODE !== "development") console.log = () => {};

// navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`);

// Notification.requestPermission(function (result) {
//   if (result === "granted") {
//     navigator.serviceWorker.ready.then(function (registration) {
//       NotificationService.register(registration);
//     });
//   }
// });

NotificationService.init();

root.render(
  // <React.StrictMode>
  <>
    <StompClient />

    <App />
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
