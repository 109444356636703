import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import "./fontawesome";
import MobileApp from "./MobileApp";

function App() {
  return <MobileApp />;
}

export default App;
