import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import RestUtils from "../Services/Rest";
import OffersDataStore from "../Store/OffersDataStore";
import Popup from "./Popup";
import RequestsDataStore from "../Store/RequestsDataStore";
import { faArrowRightArrowLeft, faPhone, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import NotificationService from "../Services/NotificationService";
import UserContext from "../Store/UserContext";
import Rating from "./Rating";
import MsgDataStore from "../Store/MsgDataStore";


export const OfferRecord = (props) => {
  const [show, setShow] = useState(false);

  const data = props.data || { vendor: {}, mealType: {}, foodItem: {} };
  const onYes = props.onYes;

  const statusFontClass = data.status === "PENDING" ? "status-red-font" : "status-green-font";

  const foodAvailableDate = new Date(data.foodAvailableDate);

  const displayDate = foodAvailableDate.toLocaleString('default', { month: 'short' }) + " " +
                      foodAvailableDate.toLocaleString('default', { day: 'numeric' }) + " - " +
                      foodAvailableDate.toLocaleString('default', { weekday : 'short'});

  const fromOrdersPage = props.fromOrdersPage || false;

  const showPopup = () => {
    setShow(true);
    console.log("showPopup:", show);
  };

  const closePopup = () => {
    setShow(false);
    console.log("closePopup: ", show);
  };

  // console.log("rendering again", show, data, UserContext.getuserId());

  return (
    <div className="pointercursor">
      <Popup
        show={show}
        onClose={() => closePopup()}
        data={data} userType="vendor" type="Food Item"
        onYes={(rec) => onYes(rec)}
        fromOrdersPage = {fromOrdersPage}
        yes={data.vendor.id === UserContext.getuserId() ? "Yes" : "Buy"}
        msg={ data.vendor.id === UserContext.getuserId() ? "Cancel" : "Buy" } />

      
      <div className="container">
        <div className="row">
          
          <div className="col">

            <div className="border-dull-white record-p record" onClick={() => showPopup()} >
              <div className="row record-row-p">
                
                <div className="col">
                  <div className="product-font">
                    {data.foodItem.name || ""}
                  </div>

                  <div className="image-container">
                    {data.foodItem.image && (
                      <img src={`${data.foodItem.image}`} alt="" />
                    )}
                    {!data.foodItem.image && <img src="/blank.png" alt="" />}
                  </div>
                </div>

                <div className="col font-coral text-right">
                    {"\u20B9"+(data.foodItem && data.foodItem.price) || ""}
                    {` (${data.quantity || ""})`}
                </div>
              </div>
                      
              <div className="row record-row-p">
                <div className="col display-date-font"> 
                  <label className="status-green-font px-2"> {displayDate} </label>
                </div>
                <div className="col display-date-font">
                  <label className={`${statusFontClass} px-2`}> 
                    {fromOrdersPage ? data.status : data.mealType} 
                  </label>
                </div>
                <div className="col flat-font text-right">
                  {/* {[1,2,3,4,5].map((v) => {
                    return (
                      <FontAwesomeIcon icon={faStar} size="sm" className={data.rateCount>= v ? "color-yellow" : "" }/>
                    );})
                  }
                  ({data.rateCount} ratings) */}
                  <Rating rating={data.rating} rateCount={data.rateCount} />
                </div>
              </div>
            </div>
          </div>

          { (!fromOrdersPage && (
            <div className="col-1">
              <a href={"tel:+91" + data.vendor.phoneNo}>
                <FontAwesomeIcon icon={faPhone} size="lg" style={{color: "green", paddingTop: "35px"}} className="mt-4"/>
              </a>
            </div>
          )) || (
            <div className="col-1">
              <FontAwesomeIcon icon={faPhone} size="lg" style={{color: "grey", paddingTop: "35px"}} className="mt-4"/>
              
            </div>
          )
          }
        </div>
      </div>
    </div>
  );
};

const CustomerPage = (props) => {
  const [mdata, setMdata] = useState([]);
  const [mdataBkp, setMdataBkp] = useState([]);
  const [ isAlertVisible, setIsAlertVisible ] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const userType = props.userType || "vendor";

  const subscribe = () => {
    OffersDataStore.subscribe("OfrPage", (msg, maction, source) =>
      notifyMe(msg, maction, source)
    );
    MsgDataStore.subscribe("MsgData", (msg, maction, source) => {
      if(source === "REQUEST"){
        displayMsg(msg);
      }
    }
    );
  };

  useEffect(subscribe, []);

  useEffect(getOffers, []);

  const displayMsg = (msg) => {
    setIsAlertVisible(true);
    setAlertMsg(msg);
    console.log("displayMsg set to true");
    setTimeout(() => {
      setIsAlertVisible(false);
      console.log("displayMsg set to false");
    }, 3000);
  };

  const notifyMe = (msg, maction, source) => {
    console.log("CustomerPage notifyMe - ", msg, maction, source);
    if (source === "MQ" && msg.vendor.id === UserContext.getuserId() && msg.version > 1) {
      NotificationService.notify(`Offer ${msg.foodItem.name} updated`);
    }
    setMdata((oldData) => {
      const newData = [...oldData];
      if (maction === "add") {
        const arr = !Array.isArray(msg) ? [msg] : msg;
        for (const ele of arr) {
          const idx = newData.findIndex((o) => o.id === ele.id);
          console.log("CustomerPage source: ", source, ", id: ", ele.id, ", idx: ", idx);
          if (idx !== -1) {
            const oldOfr = newData.splice(idx, 1)[0]; // at pos idx remove 1 element
            console.log("vendor.id: ", oldOfr.vendor.id, ", userId: ", UserContext.getuserId());
          }
          
          newData.push(ele);
          // console.log("newData: " + stringify(newData));
        }
      } else {
        return newData.filter((v) => {
          return v.id !== msg.id;
        });
      }
      return newData;
    });

    //FIXME: after adding 'action' add/remove in above func, this needs to be in sync.
    setMdataBkp((oldData) => {
      const newData = [...oldData];
      if (maction === "add") {
        const arr = !Array.isArray(msg) ? [msg] : msg;
        for (const ele of arr) {
          const idx = newData.findIndex((o) => o.id === ele.id);
          if (idx !== -1) {
            newData.splice(idx, 1); // at pos idx remove 1 element
          }
          newData.push(ele);
          // console.log("newData: " + stringify(newData));
        }
      } else {
        return newData.filter((v) => {
          return v.id !== msg.id;
        });
      }
      return newData;
    });
  };

  function search(val) {
    if (val) {
      const tmpData = mdataBkp.filter((row) => {
        return JSON.stringify(row).includes(val);
      });
      setMdata(tmpData);
    } else {
      setMdata(mdataBkp);
    }
  }

  function getOffers() {
    console.log("Making rest call - getOffers ");

    RestUtils.get("/offers?excludeCurrentUser=true", (res) => {
      OffersDataStore.add(res, "RESTAPI");
    });
  }

  
  function availOffer(data) {
    RestUtils.post("/customer/avail_offer/" + data.id, {}, (res) => {
      RequestsDataStore.add(res, "RESTAPI");
    });
  }

  const onUpdate = (data) => {
    console.log("Executing availOffer", JSON.stringify(data));

    availOffer(data);
  };

  return (
    <>
      <div className="inner-addon dual-color my-1">
        <div className="row marg">
          <div className="col marg pholder">
            <i className="fa left-fa">
              <FontAwesomeIcon icon="search" size="sm" />
            </i>
            <i className="fa right-fa">
              <Link to="/new-req">
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="2xl"
                  className="plus-icon"
                />
              </Link>
            </i>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={(event) => search(event.target.value)}
            />
          </div>
        </div>
      </div>

      
      <div className="underline text-center">
        {
          mdata.filter((val) => val.status !== "CANCELLED" && val.status !== "MATCHED").length > 0
          ? <label>You Can Buy Below Food Offered By Neighbors</label>
          : <label>No Food Orders by Neighbors yet, No worries!<br/>
          Click on <FontAwesomeIcon icon={faPlusSquare} size="lg" className="plus-icon" /> above to place Buy order.</label>
        }
          
      </div>

      {
        isAlertVisible && 
        <div className="alert-container my-4">
          <div className="alert-inner">{alertMsg}</div>
        </div>
      }

      {mdata
        .filter(
          (d) =>
            d.status !== "CANCELLED" &&
            d.status !== "MATCHED"
        )
        .filter((obj) => obj[userType].id !== UserContext.getuserId())
        .map((d, i) => (
          <OfferRecord data={d} key={i} onYes={(data) => onUpdate(data)} />
        ))}

          <div>
      <div className="text-center product-font bottom-right">
        <div
          className="col link"
          onClick={() => UserContext.setValue("vendor")}
        >
          <Link to="/vendor">
            <div className="circle icon-fa">
              <i>
                <FontAwesomeIcon icon={faArrowRightArrowLeft} size="lg" />
              </i>
              <i> </i>Sell
            </div>
          </Link>
        </div>
      </div>
      <div className="text-center bottom-center">
        <label>You are in Buyer context.</label>
      </div>
      </div>
    </>
  );
};

export default CustomerPage;
