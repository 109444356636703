const SearchRecord = (props) => {
  const foodItem = props.foodItem || {};
  const isnew = foodItem.isnew || false;
  const onSelect_ = props.onSelect;

  const onSelect = () => {
    onSelect_(foodItem);
    console.log("SearchRecord onselect called.", foodItem);
  };

  return (
    <>
      <div
        className="row record-row-p border-dull-white"
        onClick={() => onSelect()}
      >
        <div className="col product-font highlight">
          {(isnew
            ? "Create " + foodItem.name
            : `${foodItem.name} -  ₹${foodItem.price} - ${
                foodItem.menu || ""
              }`) || ""}
        </div>
      </div>
    </>
  );
};

export default SearchRecord;
