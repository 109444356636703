const UserContext = {
  value: "customer", // default value
  switchto: "vendor", // default value
  subscribersNames: [],
  subscribers: [],
  getValue() {
    return this.value;
  },
  switchTo(){
    return this.switchto;
  },
  setValue(val) {
    this.value = val;
    if(val === "customer"){
      this.switchto = "vendor";
    }else if(val === "vendor"){
      this.switchto = "customer";
    }
    this.notify(val);
  },
  subscribe(name, func) {
    const idx = this.subscribersNames.findIndex((o) => o === name);
    if (idx !== -1) {
      this.subscribersNames.splice(idx, 1);
      this.subscribers.splice(idx, 1);
      console.log("Re-subscribing: ", name, idx);
    }
    this.subscribersNames.push(name);
    this.subscribers.push(func);
    console.log("Subscribing: " + name, idx);
  },
  notify(msg) {
    for (let idx = 0; idx < this.subscribers.length; idx++) {
      const subscriberFn = this.subscribers[idx];
      const subscriberNn = this.subscribersNames[idx];
      console.log(`Calling(sub-${subscriberNn}): ${subscriberFn.name}`);
      // subscriberFn(this.data);
      subscriberFn(msg);
    }
  },
  getuserId(){
    return parseInt(localStorage.getItem("userId"));
  },
  isAdmin(){
    return JSON.parse(window.atob(localStorage.getItem("token").split(".")[1])).isAdmin || false;
  }
};

export default UserContext;
