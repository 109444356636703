import { Client } from "@stomp/stompjs";
import { useEffect, useState } from "react";
import RestUtils from "../Services/Rest";
import OffersDataStore from "../Store/OffersDataStore";
import OrdersDataStore from "../Store/OrdersDataStore";
import RequestsDataStore from "../Store/RequestsDataStore";
import SubscriptionsDataStore from "../Store/SubscriptionsDataStore";
import UserDataStore from "../Store/UserDataStore";
import NotificationService from "../Services/NotificationService";
import UserContext from "../Store/UserContext";

//TODO: merge ClientsStore and SubscriptionsDataStore
//LRN: https://stackoverflow.com/questions/34361379/are-arrow-functions-and-functions-equivalent-interchangeable
const ClientsStore = {
  clients: [],
  exists(clientNm) {
    return this.clients.findIndex((o) => o === clientNm) !== -1;
  },
  add(clientNm) {
    const idx = this.clients.findIndex((o) => o === clientNm);
    if (idx !== -1) {
      console.log("ClientsStore - add - client-", clientNm, " already exists.");
    } else {
      console.log("ClientsStore - add - adding client-", clientNm);
      this.clients.push(clientNm);
    }
  },
  clear() {
    console.log("Removing existing clients", this.clients.length);
    this.clients = [];
  },
};

const StompClient = (props) => {
  const name = "StompClient"; //TODO: refactor it later

  const [userId, setUserId] = useState(UserDataStore.getUserId());

  const [topics, setTopics] = useState({});

  const onOfferMessage = (msg) => OffersDataStore.add(msg, "MQ");
  const onRequestMessage = (msg) => RequestsDataStore.add(msg, "MQ");
  const onOrderMessage = (msg) => OrdersDataStore.add(msg, "MQ");
  const onNewuserMessage = (msg) => {
    NotificationService.notify("New User Registered.");
  };

  const destinations = [
    { name: "offerTopic", onMessage: onOfferMessage },
    { name: "requestTopic", onMessage: onRequestMessage },
    { name: "ordersTopic", onMessage: onOrderMessage },
    { name: "newuserTopic", onMessage: onNewuserMessage },
  ];

  useEffect(() => {
    onUserUpdate(userId); // to handle a case where user reopens previously logged in tab.
    UserDataStore.subscribe("StomClient", (userId, action, src) =>
      onUserUpdate(userId)
    );
  }, []);

  //upon logout clear ClientsStore

  function onUserUpdate(userId) {
    setUserId(userId);
    if (!userId) {
      ClientsStore.clear(); // this means - user logged off
    } else {
      RestUtils.get("/apartments/mq", (data) => {
        setTopics(data);
      });
    }
  }

  useEffect(() => {
    console.log("In useEffect outer.");
    if (!ClientsStore.exists(name) && userId && Object.keys(topics).length) {
      // in dev mode useEffects gets triggered 2 times, 2 avoid 2 connections
      const client = new Client({
        brokerURL: process.env.REACT_APP_MQ_URL,
        connectHeaders: {
          login: process.env.REACT_APP_MQ_USERNAME,
          passcode: process.env.REACT_APP_MQ_PASSWORD,
        },
        debug: function (str) {
          if (process.env.REACT_APP_ENVMODE === "development") {
            console.log("Socket Debug stmt:" + str);
          }
        },
        reconnectDelay: 60000,
        heartbeatIncoming: 0, // client does not want to receive heartbeats from server
        heartbeatOutgoing: 60000, // client will send heartbeats every 10secs
      });

      console.log("Socket Client is: " + client);

      client.onConnect = function (frame) {
        for (const destination of destinations) {
          if(destination.name === "newuserTopic" && !UserContext.isAdmin()){
            continue;
          }
          console.log("Connecting to", destination.name);

          const subscription = client.subscribe(
            topics[destination.name],
            (message) => {
              if (message.body) {
                // console.log("Received msg: ", message.body);
                if (typeof message.body === "string") {
                  console.log("Parsing data before calling onMessage");
                  destination.onMessage(JSON.parse(message.body));
                } else {
                  destination.onMessage(message.body);
                }
              } else {
                console.log(destination.name, " Socket got empty subs message");
              }
            },
            { id: `${destination.name}_${UserDataStore.getUserId()}` }
          );
          SubscriptionsDataStore.add({
            subscription: subscription,
            id: `${destination.name}_${UserDataStore.getUserId()}`,
            client: client,
          });
        }
      };

      client.onStompError = function (frame) {
        // Will be invoked in case of error encountered at Broker
        // Bad login/passcode typically will cause an error
        // Complaint brokers will set `message` header with a brief message. Body may contain details.
        // Compliant brokers will terminate the connection after any error
        console.log("Socket Broker reported error: ", frame.headers["message"]);
        console.log("Socket Additional details: ", frame.body);
      };

      client.onDisconnect = function (frame) {
        // Will be invoked in case of error encountered at Broker
        // Bad login/passcode typically will cause an error
        // Complaint brokers will set `message` header with a brief message. Body may contain details.
        // Compliant brokers will terminate the connection after any error
        console.log("DISCONNECT - Socket Broker reported error: ", frame);
      };

      client.activate();
      ClientsStore.add(name);
    } else {
      console.log(
        "Client already exists or userId empty - ",
        name,
        userId,
        topics
      );
    }
  }, [topics]);

  return <></>;
};

export default StompClient;
