import axios from "axios";

import { stringify } from "../Utils/CommonUtils";
import AuthenticationService from "./AuthenticationService";

class RestUtils {
  static withCredentials() {
    if (1 < 0) {
      return { withCredentials: true };
    } else {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      };
      return { headers: headers };
    }
  }

  static setAuthToken = (token) => {
    if (token) {
      console.log("added header to axios req: " + token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      console.log("deleted header");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  static api = axios.create({
    // baseURL: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/rest`,
    baseURL: process.env.REACT_APP_SERVER_URL,
  });

  static test = this.api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("Error output", error && stringify(error.response));
      if (error && (error.response.status === 401 || error.response.status === 403)) {
        AuthenticationService.removeAuth();
      }
      return Promise.reject(error);
    }
  );

  static get(url, callback) {
    // console.log("GET: " + url);

    this.api.get(url, RestUtils.withCredentials()).then((res) => {
      // console.log("GET output:" + stringify(res.data));
      callback(res.data);
    });
  }

  static put(url, data, callback) {
    // console.log("PUT: " + url + " :: " + stringify(data));

    this.api.put(url, data, RestUtils.withCredentials()).then((res) => {
      // console.log("PUT output: " + stringify(res.data));
      callback(res.data);
    });
  }

  static post(url, data, callback, errorCallback) {
    // console.log("POST: " + url + " :: " + stringify(data));

    this.api.post(url, data, RestUtils.withCredentials()).then((res) => {
      // console.log("POST output: " + stringify(res.data));
      callback(res.data);
    }).catch((reason) => {errorCallback !== undefined && errorCallback(reason)});
  }

  static delete(url, callback) {
    // console.log("DELETE: " + url);

    this.api.delete(url, RestUtils.withCredentials()).then((res) => {
      console.log("DELETE output: " + stringify(res.data));
      callback(res.data);
    });
  }

  static authenticate(data, callback, errorCb) {
    //RestUtils.setAuthToken(null);
    console.log("Authenticating user.");
    this.api
      .post("/authenticate", data, {})
      .then((res) => {
        callback(res.data);
      })
      .catch((err) => {
        console.log("Error is: ", stringify(err));
        errorCb(err.response || {});
      });
  }

  static signup(data, callback, errorCb) {
    // console.log("POST: /user/signup :: " + stringify(data));

    this.api
      .post("/user/signup", data, {})
      .then(callback)
      .catch((err) => {
        errorCb(err.response || {});
      });
  }

  static userExists(emailId, callback) {
    // console.log("GET: /user/user_exists/" + emailId);

    this.api.get("/user/user_exists/" + emailId, {}).then((res) => {
      console.log("GET userExists:" + res.data);
      callback(res.data);
    });
  }

  static login2(url, data, callback) {
    console.log("Hello");
    fetch("http://localhost:8080/login", {
      method: "POST",
      body: new URLSearchParams(data),
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("Fetch: " + stringify(res));
        console.log(data);
        console.log(
          "Cookie: " +
            stringify(data) +
            "::" +
            stringify(document.cookie)
        );
      })
      .catch((error) => {
        console.log(error);
        console.log("err: " + stringify(error));
      });
  }
}

export default RestUtils;
