import RestUtils from "../Services/Rest";
import { stringify } from "./CommonUtils";

class MenuUtils {
  static foodTypeList = [];
  static mealTypeList = [];
  static foodItemList = [];
  static othersFoodItemList = [];

  static loadFoodTypes() {
    RestUtils.get("/menu/food-types", (res) => {
      this.foodTypeList = res;
    });
  }

  static getFoodTypes() {
    if (!this.foodTypeList.length) {
      this.loadFoodTypes();
    }
    return this.foodTypeList;
  }

  static loadMealTypes() {
    RestUtils.get("/menu/meal-types", (res) => {
      this.mealTypeList = res;
      // console.log("Got response" + stringify(res));
    });
  }

  static getMealTypes() {
    if (this.mealTypeList.length < 1) {
      this.loadMealTypes();
    }
    return this.mealTypeList;
  }

  static loadFoodItems() {
    RestUtils.get("/menu/food-items", (res) => {
      this.foodItemList = res;
      console.log("loadFoodItems response" + stringify(res));
    });
  }

  static loadOthersFoodItems() {
    RestUtils.get(
      "/menu/getFoodItemsByOtherVendors?vendorId=" +
        localStorage.getItem("userId"),
      (res) => {
        this.othersFoodItemList = res;
        // console.log("Got response" + JSON.stringify(res));
      }
    );
  }

  static getFoodItems() {
    if (!this.foodItemList.length) {
      this.loadFoodItems();
    }
    return this.foodItemList;
  }

  static getOthersFoodItems() {
    if (!this.othersFoodItemList.length) {
      this.loadOthersFoodItems();
    }
    return this.othersFoodItemList;
  }

  static getFoodItemsByFoodTypeAndMealType(foodType, mealType) {
    // console.log("foodType:", foodType, "; mealType:", mealType);
    if (foodType === undefined || mealType === undefined) {
      console.log(`Undefined param foodType=${foodType}, mealType=${mealType}`);
      return [];
    }
    return this.getFoodItems().filter((foodItem) => {
      //LRN: do not replace with ===. 1 vs "1"
      return (
        (!mealType || mealType == foodItem.mealType) &&
        (!foodType || foodType == foodItem.foodType)
      );
    });
  }

  static getPriceByFoodItemId(foodItemId) {
    console.log(`getPriceByFoodItemId: ${foodItemId}`);
    const foodItems = this.getFoodItems();

    for (const element of foodItems) {
      //for(var foodItem of foodItems)
      if (element.id == foodItemId) {
        console.log(
          `Item & Price ${foodItemId}, ${element.id}}, ${element.price}`
        );
        return element.price;
      }
    }
  }
}

export default MenuUtils;
