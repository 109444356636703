const ApartmentSearchRecord = (props) => {
  const apartment = props.apartment || {};
  const onSelect_ = props.onSelect;

  const onSelect = () => {
    onSelect_(apartment);
    console.log("ApartmentSearchRecord onselect called.", apartment);
  };

  return (
    <>
      <div
        className="row record-row-p border-dull-white"
        onClick={() => onSelect()}
      >
        <div className="col product-font highlight">
          {`${apartment.name}, ${apartment.city}, ${apartment.pincode}`}
        </div>
      </div>
    </>
  );
};

export default ApartmentSearchRecord;
