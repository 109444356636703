import { BrowserRouter as Router, Route, Routes, Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MobileApp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomePage from "./Pages/Home";
import LogoutPage from "./Pages/LogoutPage";
import { PrivateRoute } from "./Components/AuthRoute";
import CustomerPage from "./mobile/CustomerPage";
import VendorPage from "./mobile/VendorPage";
import OrdersPage from "./mobile/OrdersPage";
import NewRequest from "./mobile/NewRequest";
import NewOffer from "./mobile/NewOffer";
import UserDataStore from "./Store/UserDataStore";
import UserContext from "./Store/UserContext";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import SigninPage from "./Pages/Signin";
import SignupPage from "./Pages/Signup";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Profile from "./mobile/Profile";

const Title = () => {
  const [userId, setUserId] = useState(UserDataStore.getUserId());
  const [menuIcon, setMenuIcon] = useState(UserContext.getValue());

  const location = useLocation();
  console.log("Current location: ", location);

  useEffect(() => {
    console.log("Title subscription.");
    UserDataStore.subscribe("Title", (user) => setUserId(user));
    UserContext.subscribe("MenuIcon", (menuIcon_) => {
      setMenuIcon(menuIcon_); 
      setActTab(UserContext.getValue())
    });
  }, []);

  const [activeTab, setActiveTab] = useState({
    home: location.pathname === "/home" ? "activetab" : "",
    vendor: location.pathname === "/vendor" ? "activetab" : "",
    customer: location.pathname === "/customer" ? "activetab" : "",
    ord: location.pathname === "/orders" ? "activetab" : "",
    newreq: location.pathname === "/newreq" ? "activetab" : "",
    newofr: location.pathname === "/newofr" ? "activetab" : "",
    profile: location.pathname === "/profile" ? "activetab" : "",
  });

  function setActTab(tab) {
    const newActiveTab = {
      home: "",
      vendor: "",
      customer: "",
      ord: "",
      newreq: "",
      newofr: "",
    };
    newActiveTab[tab] = "activetab";
    setActiveTab(newActiveTab);
  }
  //TODO: use below for unmount
  //useEffect(() => { return () => {setActiveTab() } }, [])
  return (
    <>
      <div className="row title pt-2">
        <div className="col text-left domain">
          <div>
            <label>eCook</label>
          </div>
        </div>

        <div className="col">
          {userId && (
            <Link to="/profile" onClick={() => setActTab("profile")}>
              <div className="text-right fontAwesomeIcon">
                <FontAwesomeIcon
                  icon={faUser}
                  className={`${activeTab.profile}`}
                />
                <div className="text-right visible-text">
                  {UserDataStore.name()}
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
      
      <div className="row sidebar pt-2">
        <div className="col-2 link">
          <Link to="/home" onClick={() => setActTab("home")}>
            <FontAwesomeIcon icon={faHome} className={`fontAwesomeIcon ${activeTab.home}`} />
          </Link>
        </div>
        <div className="col link product-font">
          <Link to={menuIcon} onClick={() => setActTab(UserContext.getValue())}>
            <label className={`tab ${activeTab[UserContext.getValue()]}`} style={{fontWeight: "bold"}}>
              {UserContext.getValue() === "vendor" ? "Sell" : "Buy"}
            </label>
          </Link>
        </div>

        
        <div className="col link">
          <Link to="/orders">
            <label className={`tab ${activeTab.ord}`} style={{fontWeight: "bold"}}
              onClick={() => setActTab("ord")}
            >
              Orders
            </label>
          </Link>
        </div>
      </div>
    </>
  );
};

const MobileApp = () => {
  return (
    <Router>
      <div className="container bg-86black container-small">
        <Title />

        <Routes>
          <Route path="/" exact forceRefresh={true} element={<HomePage />} />
          <Route
            path="/login"
            exact
            forceRefresh={true}
            element={<SigninPage />}
          />
          <Route
            path="/signup"
            exact
            forceRefresh={true}
            element={<SignupPage />}
          />
          <Route path="/home" exact element={<HomePage />} />

          <Route path="/vendor" element={<PrivateRoute />}>
            <Route path="/vendor" element={<VendorPage />} />
          </Route>

          <Route path="/customer" element={<PrivateRoute />}>
            <Route path="/customer" element={<CustomerPage />} />
          </Route>

          <Route path="/orders" element={<PrivateRoute />}>
            <Route path="/orders" element={<OrdersPage />} />
          </Route>

          <Route path="/new-req" element={<PrivateRoute />}>
            <Route path="/new-req" element={<NewRequest />} />
          </Route>

          <Route path="/new-ofr" element={<PrivateRoute />}>
            <Route path="/new-ofr" element={<NewOffer />} />
          </Route>

          <Route path="/logout" element={<LogoutPage />}></Route>

          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default MobileApp;
