import RestUtils from "../Services/Rest";
import SubscriptionsDataStore from "../Store/SubscriptionsDataStore";
import UserDataStore from "../Store/UserDataStore";

// https://ui.dev/react-router-protected-routes-authentication
// https://www.alibabacloud.com/blog/how-to-implement-authentication-in-reactjs-using-jwt_595820

class AuthenticationService {
  static authenticate(data, callback, errorCb) {
    localStorage.clear();

    // console.log("Login: " + JSON.stringify(data));
    RestUtils.authenticate(
      data,
      (res) => {
        console.log("JWT after login: " + res.jwt);
        localStorage.setItem("token", res.jwt);
        const user = JSON.parse(
          window.atob(res.jwt.split(".")[1]).toString()
        );
        localStorage.setItem("userId", parseInt(user.userId));
        localStorage.setItem("name", user.name);
        UserDataStore.add(user, "AuthService");
        console.log(`payload: ${user}`);
        callback(true);
      },
      errorCb
    );
  }

  static isAuthenticated() {
    return !!localStorage.getItem("token");
  }

  static removeAuth() {
    console.log("Removing all auth information");
    localStorage.clear();
    UserDataStore.clear();
    SubscriptionsDataStore.unsubscribe();
  }
}

export default AuthenticationService;
