const RequestsDataStore = {
  data: [],
  subscribersNames: [],
  subscribers: [],
  clear() {
    this.data = [];
  },
  init(msg) {
    //FIXME: on the init take if it is a cancel msg on new msg and handle it
    this.notify(msg, "add", "");
  },
  add(msg, source) {
    // console.log("Request msg add: " + JSON.stringify(msg));
    // Array.prototype.push.apply(this.data, msg); // this.data.push(...msg);
    this.notify(msg, "add", source);
  },
  delete(msg) {
    console.log("Request msg delete: " + JSON.stringify(msg));
    // Array.prototype.push.apply(this.data, msg); // this.data.push(...msg);
    this.notify(msg, "delete", "");
  },
  subscribe(name, func) {
    const idx = this.subscribersNames.findIndex((o) => o == name);
    if (idx !== -1) {
      this.subscribersNames.splice(idx, 1);
      this.subscribers.splice(idx, 1);
      console.log("Re-subscribing: ", name, idx);
    }
    this.subscribersNames.push(name);
    this.subscribers.push(func);
    console.log("Subscribing: " + name, idx);
  },
  notify(msg, action, source) {
    for (let idx = 0; idx < this.subscribers.length; idx++) {
      const subscriberFn = this.subscribers[idx];
      const subscriberNn = this.subscribersNames[idx];
      console.log(`Calling(sub-${subscriberNn}): ${subscriberFn.name}`);
      // subscriberFn(this.data);
      subscriberFn(msg, action, source);
    }
  },
};

export default RequestsDataStore;
