// https://coderfiles.dev/blog/react-show-hide-after-few-seconds/

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import RestUtils from "../Services/Rest";
import RequestsDataStore from "../Store/RequestsDataStore";
import Popup from "./Popup";
import OffersDataStore from "../Store/OffersDataStore";
import { faPlusSquare, faArrowRightArrowLeft, faPhone} from "@fortawesome/free-solid-svg-icons";
import NotificationService from "../Services/NotificationService";
import UserContext from "../Store/UserContext";
import MsgDataStore from "../Store/MsgDataStore";

export const RequestRecord = (props) => {
  const [show, setShow] = useState(false);

  const data = props.data || { customer: {}, mealType: {}, foodItem: {} };
  const onYes = props.onYes;

  const statusFontClass = data.status === "PENDING" ? "status-red-font" : "status-green-font";

  const foodAvailableDate = new Date(data.foodAvailableDate);
  const displayDate = foodAvailableDate.toLocaleString('default', { month: 'short' }) + " " +
                      foodAvailableDate.toLocaleString('default', { day: 'numeric' }) + " - " +
                      foodAvailableDate.toLocaleString('default', { weekday : 'short'});

  const fromOrdersPage = props.fromOrdersPage || false;

  const showPopup = () => {
    if (data.status !== "MATCHED") {
      setShow(true);
    }
  };

  const closePopup = () => {
    setShow(false);
    // console.log("closePopup: ", show);
  };

  // console.log(
  //   "rendering again",
  //   show,
  //   data.customer.id,
  //   localStorage.getItem("userId")
  // );

  return (
    <div className="pointercursor">
      
      <Popup
        show={show}
        onClose={() => closePopup()}
        data={data}
        userType="customer"
        type="Food Item"
        onYes={(rec) => onYes(rec)}
        yes="Yes"
        fromOrdersPage = {fromOrdersPage}
        msg={ data.customer.id === UserContext.getuserId() ? "Cancel" : "Sell" } />

      <div className="container">
        <div className="row">
          
          <div className="col">
              <div className="border-dull-white record-p record" onClick={() => showPopup()} >
            
              <div className="row record-row-p">
                
                <div className="col">
                  <div className="col product-font">
                    {(data.foodItem && data.foodItem.name) || ""}
                  </div>

                  <div className="image-container">
                    {data.foodItem.image && (
                      <img src={`${data.foodItem.image}`} alt="" />
                    )}
                    {!data.foodItem.image && <img src="/blank.png" alt="" />}
                  </div>
                </div>
                

                <div className="col font-coral text-right">
                  {"\u20B9"+(data.foodItem && data.foodItem.price) || ""}
                  {` (${data.quantity || ""})`}
                </div>
              </div>

              <div className="row record-row-p">
                <div className="col flat-font" style={{color: "white"}}>
                  {displayDate}
                </div>
                <div className={`col ${statusFontClass}`}>
                  <label className={`${statusFontClass} px-2`}> 
                    {fromOrdersPage ? data.status : data.mealType} 
                  </label>
                </div>
                <div className="col flat-font text-right">
                  {fromOrdersPage ? data.mealType : ""}
                </div>
              </div>
            </div>
          </div>

          { (!fromOrdersPage && (
            <div className="col-1">
              <a href={"tel:+91" + data.customer.phoneNo}>
                <FontAwesomeIcon icon={faPhone} size="lg" style={{color: "green", paddingTop: "35px"}} className="mt-4"/>
              </a>
            </div> )) || 
            (
            <div className="col-1">
              <FontAwesomeIcon icon={faPhone} size="lg" style={{color: "grey", paddingTop: "35px"}} className="mt-4"/>
              
            </div>
          )
          }
        </div>
      </div>
    </div>
  );
};

const VendorPage = (props) => {
  const [mdata, setMdata] = useState([]);
  const [mdataBkp, setMdataBkp] = useState([]);
  const userType = props.userType || "customer";
  const [ isAlertVisible, setIsAlertVisible ] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const subscribe = () => {
    RequestsDataStore.subscribe("ReqPage", (msg, maction, source) =>
      notifyMe(msg, maction, source)
    );
    MsgDataStore.subscribe("MsgData", (msg, maction, source) => {
      if(source === "OFFER"){
        displayMsg(msg);
      }
    }
    );
  };

  useEffect(subscribe, []);

  useEffect(getRequests, []);

  const displayMsg = (msg) => {
    setIsAlertVisible(true);
    setAlertMsg(msg);
    console.log("displayMsg set to true");
    setTimeout(() => {
      setIsAlertVisible(false);
      console.log("displayMsg set to false");
    }, 3000);
  };

  const notifyMe = (msg, maction, source) => {
    console.log("VendorPage notifyMe - ", msg, maction, source, msg.length);
    if (source === "MQ" && msg.customer.id === UserContext.getuserId() && msg.version > 1) {
      NotificationService.notify(`Request ${msg.foodItem.name} updated`);
    }

    setMdata((oldData) => {
      const newData = [...oldData];
      if (maction === "add") {
        const arr = !Array.isArray(msg) ? [msg] : msg;
        for (const ele of arr) {
          const idx = newData.findIndex((o) => o.id === ele.id);
          console.log("VendorPage source: ", source, ", id: ", ele.id, ", idx: ", idx);
          if (idx !== -1) {
            const oldReq = newData.splice(idx, 1)[0]; // at pos idx remove 1 element
            console.log("customer.id: ", oldReq.customer.id, ", userId: ", UserContext.getuserId());
          }
          
          newData.push(ele);
          // console.log("newData: " + stringify(newData));
        }
      } else {
        return newData.filter((v) => {
          return v.id !== msg.id;
        });
      }
      return newData;
    });

    //FIXME: as part of refactoring remove redundancy of these two funcs
    setMdataBkp((oldData) => {
      const newData = [...oldData];
      if (maction === "add") {
        const arr = !Array.isArray(msg) ? [msg] : msg;
        for (const ele of arr) {
          const idx = newData.findIndex(
            (o) => o !== undefined && o.id === ele.id
          );
          if (idx !== -1) {
            newData.splice(idx, 1); // at pos idx remove 1 element
          }
          newData.push(ele);
          // console.log("newData: " + stringify(newData));
        }
      } else {
        return newData.filter((v) => {
          return v.id !== msg.id;
        });
      }
      return newData;
    });
  };

  function search(val) {
    if (val) {
      const tmpData = mdataBkp.filter((row) => {
        return JSON.stringify(row).includes(val);
      });
      setMdata(tmpData);
    } else {
      setMdata(mdataBkp);
    }
  }

  function getRequests() {
    console.log("Making rest call - getRequests ");

    RestUtils.get("/requests/others", (res) => {
      RequestsDataStore.add(res, "RESTAPI");
    });
  }

  function cancel(data) {
    RestUtils.delete("/requests/" + data.id, (res) => {
      RequestsDataStore.delete(res);
    });
  }

  function acceptRequest(data) {
    RestUtils.post("/vendors/accept-request/" + data.id, {}, (res) => {
      OffersDataStore.add(res, "RESTAPI");
    });
  }

  const onUpdate = (data) => {
    console.log("Executing onUpdate", JSON.stringify(data));
    if (data.customer.id === UserContext.getuserId()) {
      cancel(data);
    } else {
      acceptRequest(data);
    }
  };

  return (
    <>
      <div className="inner-addon dual-color  my-1">
        <div className="row marg">
          <div className="col marg inner-addon-left pholder">
            <i className="fa left-fa">
              <FontAwesomeIcon icon="search" size="sm" />
            </i>
            <i className="fa right-fa">
              <Link to="/new-ofr">
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="2xl"
                  className="plus-icon"
                />
              </Link>
            </i>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={(event) => search(event.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="underline text-center">
        {
        mdata.filter((val) => val.status !== "CANCELLED" && val.status !== "MATCHED").length > 0
          ? <label>You Can Sell Below Food Requested by Neighbors</label>
          : <label>No Food Requests from Neighbors yet.<br/>
          Click on <FontAwesomeIcon icon={faPlusSquare} size="lg" className="plus-icon" /> above to place Sell order.</label>
        }
      </div>

      {
        isAlertVisible && 
        <div className="alert-container  my-4">
          <div className="alert-inner">{alertMsg}</div>
        </div>
      }

      {mdata
        .filter(
          (d) =>
            d.status !== "CANCELLED" &&
            d.status !== "MATCHED"
        )
        .filter((obj) => obj[userType].id !== UserContext.getuserId())
        .map((d, i) => (
          <RequestRecord data={d} key={i} onYes={(data) => onUpdate(data)} />
        ))}

          <div>
      <div className="text-center product-font bottom-right">
        <div className="col link"
          onClick={() => UserContext.setValue("customer")}>
          <Link to="/customer">
            <div className="circle icon-fa">
              <i>
                <FontAwesomeIcon icon={faArrowRightArrowLeft} size="lg" />
              </i>
              <i> </i>Buy
            </div>
          </Link>
        </div>
      </div>
      <div className="text-center bottom-center">
        <label>You are in Seller context.</label>
      </div>
      </div>
    </>
  );
};

export default VendorPage;
