import RestUtils from "../Services/Rest";

class ApartmentUtils {
  static apartments = [];

  static loadApartments() {
    RestUtils.get("/apartment", (res) => {
      this.apartments = res;
      console.log("apartments - Got response" + JSON.stringify(res));
    });
  }

  static getApartments() {
    if (!this.apartments.length) {
      this.loadApartments();
    }
    return this.apartments;
  }
}

export default ApartmentUtils;
